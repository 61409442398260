<template lang="pug">
  .wrapper.i-wrap()
    .section-left()
    .section-mid(style="width:100%")
      .container.i-coming-soon-wrap(v-if="!loading")
          .sectionTitle
            p 現正熱映
          .flexBreak
          .genre-list-wrap
            .genre-wrap(v-for="(gen, index) in genreListEN")
              .genre.pointer(@click="genreToggle(index)" :class="{active: genre.includes(gen) }") {{genreListCH[index]}}
          p.pointer(v-if="savedState && page > 1" @click="lastPage()" style="margin-top:20px;") 上一頁
      transition(name="fade")
        .container(v-if="!loading")
          .i-coming-soon-wrap(v-if="mtes.length > 0")
            .i-coming-soon(v-for="mte in mtes")
              mtesCard(:mtes="mte")
        .i-coming-soon-wrap(v-else)
          p 沒有更多結果
        .container.i-config-soon-wrap(v-else style="text-align:center; justify-content:center")
          Spinner
      InfiniteLoading(v-if="genre.length > 0" :identifier="infiniteId" @infinite="infiniteHandler")
        div( slot="spinner") 
          Spinner
        div( slot="no-more") 沒有更多結果
        div( slot="no-results") 沒有更多結果
    .section-right

</template>

<script>
import mtesCard from '@/components/mtesCard'
import Spinner from 'vue-simple-spinner'
import InfiniteLoading from 'vue-infinite-loading';

export default {
  name: 'GenreList',
  components: {
    Spinner,
    mtesCard,
    InfiniteLoading,
  },
  props:{
    model:String,
    setGenre:String,
  },
  data(){
    return{
      loading:true,
      mtes:[],
      queryString:'',
      page:0,
      genre:[],
      genreListEN: ['Action','Adventure','Animation','Comedy','Crime','Documentary','Drama','Family','Fantasy','History','Horror','Music','Mystery','Romance','Science Fiction','TV Movie','Thriller','War','Western'],
      genreListCH: ['動作','冒險','動畫','喜劇','犯罪','紀錄片','劇情片','家庭','奇幻','歷史','恐怖','音樂','懸疑' ,'浪漫','科幻','電視電影','驚悚片','戰爭','西部'],
      endOfPosts:false,
      infiniteId: +new Date(),
      savedState:false,
    }
  },
  metaInfo: {
    title: '类型',
    titleTemplate: '%s - 臭豆腐 - 電影∣影集∣評分∣評論'
  },
  methods:{
    async init(){
      if(this.$route.query.page){
        this.page = parseInt(this.$route.query.page)
        if(this.page > 0) this.savedState = true;
      }else{
        this.page = 0;
      }
      if(this.setGenre) {
        await this.genre.push(this.setGenre)
        this.loading = false;
      }else{
        this.loading = false;
      }
    },    
    async genreToggle(index){
      this.loading = true;
      this.page = 0;
      this.mtes = [];
      const isIn = (ele)=> ele == this.genreListEN[index]
      var x = await this.genre.findIndex(isIn)
      if(x > -1){
        this.genre.splice(x,1)
      }else{
        this.genre.push(this.genreListEN[index])
      }
      this.changeType()
      this.loading = false;
    },
    async lastPage(){
      this.loading = true;
      this.page = this.page - 2;
      this.mtes = [];
      this.changeType()
      this.loading = false;
    },
    changeType() {
      this.infiniteId += 1;
    },
    async search(){
      this.loading = true;
      var response = await this.axios.post(`/api/genre/${this.model}`,{genre:this.genre, page: this.page})
      this.mtes = response.data;
      this.loading = false;      
    },
    async loadMtes(){
      var {data} = await this.axios.post(`/api/genre/${this.model}`,{genre:this.genre, page: this.page})
      if(data.length > 0){
        this.mtes.push(data)

      }else{
        this.endOfPosts = true;
      }
    },
    async infiniteHandler($state){      
      var {data} = await this.axios.post(`/api/genre/${this.model}`,{genre:this.genre, page: this.page})

      if(data.error){
        this.errorMsg = data.errorMsg
        $state.complete();
      }else{
        // this.mtes.push(...data.mtes)
        if(data.mtes.length > 0){
          this.mtes.push(...data.mtes)
          history.replaceState(null, 'hi', `/genre/${this.model}/${this.genre}?page=${this.page}`)
          this.page += 1;
          // this.$router.replace(`/genre/${this.model}/${this.genre}?page=${this.page}`)
          $state.loaded();
        }else{
          $state.complete();
        }
      }
      
    },    
  },
 
  async created(){
    this.init();      
  }
}
</script>
<style lang="scss" scoped>
  .genre{
    border:solid 1px lightgrey;
    padding:5px 10px;
    border-radius:5px;
    margin-right:10px;      
    margin:10px;
  }
  .genre.active{
    background: lightgrey;
  }
  .genre-list-wrap{
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .i-coming-soon-wrap{
    width:100%;
    flex-wrap:wrap;
    justify-content: center;
    display:flex;
  }
  .i-coming-soon.active{
    height:300px;
  }
  .i-coming-soon{
    margin:10px;
    max-width:300px;
    flex-grow:0;
    // flex-basis:300px;
  }
  

</style>
